








































































import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  useContext,
} from '@nuxtjs/composition-api';
import { SfCarousel, SfLink } from '@storefront-ui/vue';

import { addBasePath } from '~/helpers/addBasePath';
import { SliderItem } from '~/queries/types';

export default defineComponent({
  name: 'HomeCarousel',
  components: {
    SfCarousel,
    SfLink,
  },
  props: {
    sliders: {
      type: Array as PropType<SliderItem[]>,
      required: true,
      default: () => [],
    },
  },
  setup(props) {
    const { app } = useContext();
    const { isDesktop } = app.$device;
    const desktopSliders = computed(() =>
      props.sliders.filter((slider: SliderItem) => slider.visible_on === 1)
    );
    const mobileSliders = computed(() =>
      props.sliders.filter((slider: SliderItem) => slider.visible_on === 2)
    );
    // const viewWidth = computed(() => {
    //   if (window && window.document) {
    //     return `${window.innerWidth || document.documentElement.clientWidth}px`;
    //   }
    //   return '100%';
    // });
    const viewWidth = ref<String>('1920px');
    const onResize = () => {
      if (window && window.document) {
        viewWidth.value = `${
          window.innerWidth || document.documentElement.clientWidth
        }px`;
      }
      // return '100%';
    };
    onMounted(() => {
      if (window) {
        onResize();
        // window.addEventListener('resize', onResize, true);
      }
    });

    return {
      desktopSliders,
      mobileSliders,
      isDesktop,
      addBasePath,
      viewWidth,
    };
  },
});
