

































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';

import { Promotion } from '~/queries/types';

export default defineComponent({
  name: 'PromotionActivities',
  components: {
    SfButton,
  },
  props: {
    promotions: {
      required: true,
      type: Object as PropType<Promotion | null>,
      default: () => null,
    },
  },
});
